const cache = new Map();
type CacheKey = "email" | "activeKey" | "bookmarks" | "bookmarksSynced";

cache.set("bookmarks", []);
const db =
  typeof localStorage === "undefined"
    ? {}
    : JSON.parse(localStorage.getItem("cache") || "{}");

Object.keys(db).forEach((k) => cache.set(k, db[k]));

export default {
  get: (key: CacheKey) => {
    return cache.get(key);
  },
  set: (key: CacheKey, value: any, persist?: boolean) => {
    if (persist) {
      value === null ? delete db[key] : (db[key] = value);
      localStorage.setItem("cache", JSON.stringify(db));
    }
    return value === null ? cache.delete(key) : cache.set(key, value);
  },
};
